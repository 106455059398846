import React from "react"
import Layout from "../components/layout/Layout/Layout"
import { graphql } from "gatsby"
import BusinessListings from "../components/BusinessListing/BusinessListings"
import BusinessFilter from "../components/BusinessFilter/BusinessFilter"
import HeroBanner from "../components/HeroBanner/HeroBanner"
import Breadcrumb from "../components/Breadcrumb/Breadcrumb"
import GridContainer from "../components/layout/GridContainer/GridContainer"

// Todo Add listing data for content types other than Pages
export const query = graphql`
  query {
    allDatoCmsLocalBusiness(sort: { fields: title, order: ASC }) {
      nodes {
        ...BusinessListing
      }
    }
    allDatoCmsBusinessCategory(sort: { fields: categoryName, order: ASC }) {
      nodes {
        categoryName
      }
    }
  }
`

const LocalBusinessPage = props => {
  return (
    <Layout
      metaTitle={"Local Business"}
      metaDescription={"List of all local buisinesses in Selsey."}
      metaImage={null}
      twitterCard={null}
      pathname={props.uri}
    >
      <HeroBanner title="Local Business" />
      <GridContainer>
        <Breadcrumb url={props.uri} />
      </GridContainer>
      <BusinessFilter
        current="All Businesses"
        links={props.data.allDatoCmsBusinessCategory.nodes}
      />
      <BusinessListings listings={props.data.allDatoCmsLocalBusiness.nodes} />
    </Layout>
  )
}

export default LocalBusinessPage
